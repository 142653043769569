<template>
  <div class="home-block__big-row big-row">
    <i class="fal fa-wallet"></i>
    <div class="big-row__label">
      <div class="big-row__number">
        {{ boughtTests }}
      </div>
      <div class="big-row__text">тестирований куплено</div>
    </div>
  </div>
  <div class="home-block__big-row big-row">
    <i class="fal fa-poll-people"></i>
    <div class="big-row__label">
      <div class="big-row__number">
        {{ issuedTests }}
      </div>
      <div class="big-row__text">тестирований выдано</div>
    </div>
  </div>
  <router-link to="/">
    <div class="home-block__big-row big-row">
      <i class="fal fa-box"></i>
      <div class="big-row__label">
        <div class="big-row__number">
          {{ remainingTests }}
        </div>
        <div class="big-row__text">тестирований осталось</div>
      </div>
    </div>
  </router-link>
  <div class="home-block__big-row big-row">
    <i class="fal fa-ball-pile"></i>
    <div class="big-row__label">
      <div class="big-row__number">
        {{ issuedGroups }}
      </div>
      <div class="big-row__text">распределено по группам</div>
    </div>
  </div>
  <div class="home-block__big-row big-row">
    <i class="fal fa-repeat"></i>
    <div class="big-row__label">
      <div class="big-row__number">
        {{ tableData.repeat.count }}
      </div>
      <div class="big-row__text">повторно пройдено тестов</div>
    </div>
  </div>
</template>

<script>
import { toRef } from "@vue/reactivity";
import { computed } from "vue";
export default {
  name: "home-table1",
  props: {
    tableData: Object,
    users: Number,
  },
  setup(props) {
    const tableData = toRef(props, "tableData");
    const boughtTests = computed(
      () =>
        (tableData.value.all.start ?? 0) +
        (tableData.value.all.standard ?? 0) +
        (tableData.value.all.full ?? 0) +
        (tableData.value.all.empty ?? 0)
    );
    const issuedTests = computed(
      () =>
        (tableData.value.issuedUsers.start ?? 0) +
        (tableData.value.issuedUsers.standard ?? 0) +
        (tableData.value.issuedUsers.full ?? 0) +
        (tableData.value.issuedUsers.empty ?? 0)
    );
    const remainingTests = computed(
      () => boughtTests.value - issuedTests.value
    );
    const issuedGroups = computed(
      () =>
        (tableData.value.issuedGroups.start ?? 0) +
        (tableData.value.issuedGroups.standard ?? 0) +
        (tableData.value.issuedGroups.full ?? 0) +
        (tableData.value.issuedGroups.empty ?? 0)
    );

    return {
      boughtTests,
      issuedTests,
      remainingTests,
      issuedGroups,
    };
  },
};
</script>

<style lang="scss" scoped>
a .big-row {
  border-bottom: 1px solid var(--cream-color);
}
.big-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 40px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--cream-color);
  }

  &__number {
    text-align: end;
    font-weight: 400;
    font-size: 20px;
  }

  &__text {
    font-size: 15px;
  }

  &__btn {
    min-width: 138px;
  }

  i {
    color: var(--main-color);
    font-size: 40px;
    flex-basis: 50px;
    text-align: center;
  }
}
</style>